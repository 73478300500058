<template>
    <div class="page-layout d-flex justify-content-center align-items-center">
        <a-empty :description="false" />
    </div>
</template>
<script>
import system_settings_types from '@/modules/store/system-settings/types.js'
import { mapGetters, mapMutations } from "vuex";

export default {
    name: 'Dashboard',
    data() {
        return {}
    },
    components: {
    },
    computed: {
        ...mapGetters({
            getUserDetails: system_settings_types.GET_USER_DETAILS
        }),
    },
    methods: {
    },
    mounted(){
    }
}
</script>
<style scoped>
    .page-layout {
        height: 100vw !important;
    }

    .message-user {
        font-size: 40px !important;
        font-weight: bold;
    }

    .ant-layout-content {
        height: 100vw !important;
    }
</style>