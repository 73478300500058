<template>
  <v-select
    class="sf-facility-select"
    placeholder="Search facility by name"
    label="name"
    :value="facility"
    :options="paginated"
    :filterable="false"
    :components="{Deselect}"
    @open="onOpen"
    @close="onClose"
    @search="(query) => (search = query)"
    @option:selected="handleFacilitySelected"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>

    <template v-slot:option="option">
      <div :style="{ textWrap: 'auto' }">{{ option.name }}</div>
      <div  :style="{ textWrap: 'auto', fontSize: '11px' }">{{ option.address }}</div>
    </template>

    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        No results found for <em>{{ search }}</em>.
      </template>
      <em v-else style="opacity: 0.5">Start typing to search for a facility.</em>
    </template>

  </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FacilitySelect',
  model: {
    prop: 'value',
    event: 'selected'
  },
  props: {
    value: {
      type: Object,
      require: false,
      default: () => {}
    }
  },
  data() {
    return {
      observer: null,
      limit: 10,
      search: '',
      selectedFacility: null,
      Deselect: {
        render: (createElement) => {
            return createElement('span', {
              on: {
                click: ()=>{
                  this.$emit('deselect', this);
                }
              }
            },'❌');
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      sf_facilities: 'salesforce/facilities/getFacilities',
      sf_total_size: 'salesforce/facilities/getTotalSize'
    }),
    filtered() {
      return this.sf_facilities.filter(
        (_facility) => _facility?.name?.toLowerCase()?.includes(
          this.search?.toLowerCase()
        )
      )
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return +this.paginated.length < +this.filtered.length
    },
    facility() {
      return this.selectedFacility || this.value;
    }
  },
  watch: {
    value: {
      handler: function (n, o) {
        this.selectedFacility = n;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.$on('deselect', this.handleFacilityDeselected);
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);

    // if total rows != current load rows
    // auto despatch
    if( +this.sf_total_size > 0 && +this.sf_facilities.length > 0 && (+this.sf_total_size > +this.sf_facilities.length) ) {
      this.$store.dispatch('salesforce/facilities/fetchMoreFacilities');
    }
  },
  methods: {
    onOpen: async function () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose: function () {
      this.observer.disconnect()
    },
    infiniteScroll: async function ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    handleFacilitySelected: function (selected) {
      this.selectedFacility = selected;
      this.$emit("selected", this.selectedFacility);
    },
    handleFacilityDeselected: function (e) {
      this.selectedFacility = null;
      this.$emit("selected", this.selectedFacility);
    }
  },
}
</script>

<style scoped>
.sf-facility-select >>> .loader {
  text-align: center;
  color: #bbbbbb;
}
.sf-facility-select >>> .vs__search::placeholder,
.sf-facility-select >>> .vs__dropdown-toggle {
  padding-left: 5px;
  line-height: 30px;
  font-size: 14px;
  font-variant: "tabular-nums";
  font-feature-settings: 'tnum';
}
</style>
