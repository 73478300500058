var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Subscriber"},on:{"cancel":_vm.handleClose}},[_c('template',{slot:"footer"},[_c('a-button',{key:"save",attrs:{"type":"primary","icon":"save","loading":!!_vm.saving,"disabled":!!_vm.loading},on:{"click":_vm.handleSave}},[_vm._v(" "+_vm._s(!!_vm.saving ? 'Saving...' : 'Save')+" ")]),_c('a-button',{key:"delete",attrs:{"type":"danger","icon":"delete","loading":!!_vm.deleting,"disabled":!!_vm.loading,"ghost":""},on:{"click":_vm.handleDelete}},[_vm._v(" "+_vm._s(!!_vm.deleting ? 'Deleting...' : 'Delete')+" ")]),_c('a-button',{key:"close",attrs:{"icon":"close-circle","disabled":!!_vm.loading},on:{"click":_vm.handleClose}},[_vm._v(" Close ")])],1),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: 'Subscriber name is required!' }],
          },
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: 'Subscriber name is required!' }],\n          },\n        ]"}]},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                message: 'Please input a valid email address!',
              },
              {
                required: true,
                message: 'Email address is required!',
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: 'Please input a valid email address!',\n              },\n              {\n                required: true,\n                message: 'Email address is required!',\n              },\n            ],\n          },\n        ]"}]},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }