<template>
  <div class="page-layout poppins-regular">
      <div class="form-container d-flex justify-content-center align-items-center flex-col form-fields">
          <img class="app-icon" src="https://webapp.eshyft.com/assets/loginAssets/Logo.svg" />
          <p>{{ getErrorMessage() }}</p>
          <div class="heading-1 poppins-semibold">Subscribed Now</div>
          <div class="message-1">
            <a-input v-model="email" placeholder="Enter your email address" align="center"></a-input>
          </div>
          <div class="button-1">
              <a class="btn" href="#" @click.prevent="sendRequest">Subscribe</a>
          </div>
      </div>
  </div>
</template>
<script>
import error_messages from '@/constants/error_types.js'
import axios from "axios";

export default {
  name: 'Subscribe',
  data() {
    return {
      email: null
    }
  },
  methods: {
    getErrorMessage(){
      if(this.$route.params.error){
          return error_messages[this.$route.params.error]
      }
    },
    async sendRequest() {
      if( this.email.trim().length === 0) {
        return;
      }

      await axios.post(process.env.VUE_APP_ROOT_API+'sub/subscribe', {
        email: this.email
      }).then((response) => {
          if (Object.hasOwn(response.data, 'message')) {
              this.$message.success(response.data.message);
          }else{
              this.$message.success('Success!');
          }

          this.email = null;
      }, (error) => {
          const error_message = error.response.data

          if (Object.hasOwn(error_message, 'message')) {
              this.$message.error(error_message.message);
          }else{
              this.$message.error('Encounter an issue while processing your request');
          }

          if(Object.hasOwn(error_message, 'errors')){
              for (const key in error_message.errors) {
                  if (Object.hasOwn(error_message.errors, key)) {
                      this.$message.error(error_message.errors[key].msg);
                  }
              }
          }
      });
    }
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Poppins:wght@400;600&display=swap');

.form-container {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  max-width: 576px;
  box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
  -webkit-box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
  -moz-box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
  background-color: white;
  padding: 30px;
  min-width: 476px;
}

.page-layout {
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://eshyft.com/wp-content/uploads/2022/05/Website-Homepage.png);
}

.app-icon {
  margin-bottom: 10px !important;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 50px;
    width: 650px;
}

.heading-1 {
    font-size: 28px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.message-1 {
    text-align: justify;
}

.button-1 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.btn {
    padding: 14px 35px;
    background-color: #EE474E;
    line-height: 24px;
    color: #fff;
    border-radius: 1000px;
    box-shadow: none;
    text-decoration: none;
    transition: all .3s;
}

.btn:hover {
    color: #EE474E;
    background-color: #fff;
    border: 1px solid #EE474E;
    transition: all .3s ease;
}
</style>
