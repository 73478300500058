<template>
  <a-modal
    :visible="visible"
    title="Subscriber"
    @cancel="handleClose"
  >
    <template slot="footer">
      <a-button
        key="save"
        type="primary"
        icon="save"
        :loading="!!saving"
        :disabled="!!loading"
        @click="handleSave"
      >
        {{ !!saving ? 'Saving...' : 'Save' }}
      </a-button>
      <a-button
        key="delete"
        type="danger"
        icon="delete"
        :loading="!!deleting"
        :disabled="!!loading"
        ghost
        @click="handleDelete"
      >
        {{ !!deleting ? 'Deleting...' : 'Delete' }}
      </a-button>
      <a-button
        key="close"
        icon="close-circle"
        :disabled="!!loading"
        @click="handleClose"
      >
        Close
      </a-button>
    </template>

    <a-form :form="form">
      <a-form-item label="Name">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: 'Subscriber name is required!' }],
            },
          ]"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item label="Email">
        <a-input
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Please input a valid email address!',
                },
                {
                  required: true,
                  message: 'Email address is required!',
                },
              ],
            },
          ]"
        >
          <a-icon slot="prefix" type="mail" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data: () => {
    return {
      saving: false,
      deleting: false
    }
  },
  computed: {
    ...mapGetters({
      subscriber: 'subscribers/getSubscriber'
    }),
    visible() {
      return !!this.value;
    }
  },
  watch: {
    subscriber: function(v, o) {
      if( v === o ) return;

      this.form.setFieldsValue({
        name: v?.name,
      });

      this.form.setFieldsValue({
        email: v?.email,
      });
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'update' });

    this.form.getFieldDecorator('name', {
      initialValue: this?.subscriber?.name,
    });

    this.form.getFieldDecorator('email', {
      initialValue: this?.subscriber?.email,
    });
  },
  methods: {
    handleSave: function () {
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try{
            this.saving = true;
            this.loading = true;

            const data = { id: this.subscriber.id, ...values };

            await this.$store.dispatch('subscribers/updateSubscriber', data);

            this.$store.dispatch('subscribers/setSubscriber', {});

            this.saving = false;
            this.loading = false;

            this.$emit('input', false);

            this.$success({
              title: 'Success',
              content: 'Subscriber has been successfully updated!'
            });
          } catch(error) {
            this.saving = false;
            this.loading = false;

            this.$error({
              title: 'Failed',
              content: error.response.message
            });
          }
        }
      });
    },
    handleDelete: function () {
      this.$confirm({
        title: 'Are you sure to delete this subscriber?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          this.deleting = true;
          this.loading = true;

          try {
            await this.$store.dispatch('subscribers/deleteSubscriber', { id: this.subscriber.id });
            this.$store.dispatch('subscribers/setSubscriber', {});

            this.deleting = false;
            this.loading = false;
            this.$emit('input', false);

            this.$success({
              title: 'Success',
              content: 'Subscriber has been successfully deleted!',
            });
          }catch(error) {
            this.deleting = false;
            this.loading = false;

            this.$error({
              title: 'Failed',
              content: error.response.message
            });
          }
        }
      });
    },
    handleClose: function () {
      this.$store.dispatch('subscribers/setSubscriber', {});
      this.$emit('input', false);
    }
  },
};
</script>
