export default {
    //getters
    GET_SIDEBAR_VISIBILITY: 'system_settings/GET_SIDEBAR_VISIBILITY',
    GET_USER_DETAILS: 'system_settings/GET_USER_DETAILS',
    GET_USER_PHOTO: 'system_settings/GET_USER_PHOTO',

    //mutation
    SET_SIDEBAR_VISIBILITY: 'system_settings/SET_SIDEBAR_VISIBILITY',
    SET_USER_DETAILS: 'system_settings/SET_USER_DETAILS',

    //actions
    GENERATE_DUMMY_USER: 'system_settings/GENERATE_DUMMY_USER',
    FETCH_USER_PHOTO: 'system_settings/FETCH_USER_PHOTO'
}
