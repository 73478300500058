<template>
  <div class="sf-contact-list-item">
    <div v-if="hasCheckbox" class="sf-contact-input">
      <a-checkbox :checked="isChecked" :value="value" @change="handleOnChange" />
    </div>
    <div class="sf-contact-info" @click="handleClick">
      <div v-if="contactName" class="sf-contact-name">{{ contactName }}</div>
      <div v-if="contactEmail" class="sf-contact-email">{{ contactEmail }}</div>
      <div v-if="contactDetails" class="sf-contact-detail">{{ contactDetails }}</div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      require: false,
      default: () => {}
    },
    modelValue: {
      type: Array,
      require: false,
      default: () => []
    },
    trueValue: { default: true },
    falseValue: { default: false },
    hasCheckbox: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  computed: {
    contactName: function () {
      return this.value?.name || null;
    },
    contactEmail: function () {
      return this.value?.email || null;
    },
    itemValue: function () {
      return { name: this.contactName, email: this.contactEmail }
    },
    contactDetails: function () {
      if ( this.value?.type === 'contact' ) {
        const { position, license, contact_stage, account } = this.value;
        return [contact_stage, position, license, account].filter(i => ![null,undefined,''].includes(i)).join(' | ');
      }

      if ( this.value?.type === 'lead' ) {
        const { position, company, title, status, business } = this.value;
        return [title, position, status, company, business].filter(i => ![null,undefined,''].includes(i)).join(' | ');
      }

      if ( this.value?.type === 'facility' ) {
        const { address } = this.value;
        return [address].filter(i => ![null,undefined,''].includes(i)).join(' | ');
      }


      return null;
    },
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue?.some(i => i?.email === this.contactEmail && i?.name === this.contactName);
      }

      return this.modelValue === this.trueValue;
    }
  },
  methods: {
    handleClick: function (e) {
      if( !this.hasCheckbox ) {
        e.preventDefault();
        return;
      }

      let isChecked = this.isChecked;

      if (this.modelValue instanceof Array) {
        let selected = [...this.modelValue]

        if (isChecked) {
          selected.splice(selected.indexOf(this.itemValue), 1);
        } else {
          selected.push(this.itemValue);
        }

        this.$emit('change', selected)
      } else {
        this.$emit('change', isChecked ? this.falseValue : this.trueValue);
      }
    },
    handleOnChange: function (e) {
      if( !this.hasCheckbox ) {
        return;
      }

      let isChecked = e.target.checked

      if (this.modelValue instanceof Array) {
        let selected = [...this.modelValue]

        if (isChecked) {
          selected.push(this.itemValue)
        } else {
          selected.splice(selected.indexOf(this.itemValue), 1)
        }

        this.$emit('change', selected)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  },
}
</script>

<style scoped>
.sf-contact-list-item {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  cursor: pointer;
}

.sf-contact-input {
  padding: 2px 10px 0 0;
}

.sf-contact-info {
  width: 100%;
}

.sf-contact-name,
.sf-contact-email,
.sf-contact-detail {
  padding: 0;
  margin: 0;
  width: 100%;
}

.sf-contact-name {
  font-weight: 600;
}

.sf-contact-email,
.sf-contact-detail {
  font-size: 12px;
}

.sf-contact-detail {
  text-transform: uppercase;
  font-size: 11px;
}
</style>
