<template>
    <div class="page-layout">
        <div class="form-container d-flex justify-content-center align-items-center flex-col form-fields">
            <img class="app-icon" src="https://eshyft.com/wp-content/uploads/2019/07/cropped-eshyftlogoweb-300x70.png" />
            <p>{{ getErrorMessage() }}</p>
            <a-button class="w-70 mt-8" type="primary" @click="googleLogin">
                <a-icon type="google" /> Login with google
            </a-button>
        </div>
    </div>
</template>
<script>
import error_messages from '@/constants/error_types.js'

export default {
    name: 'Login',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
        googleLogin(){
            window.open(
                `${process.env.VUE_APP_ROOT_API}auth/google/callback`,
                "_self"
            );
        },
        getErrorMessage(){
            if(this.$route.params.error){
                return error_messages[this.$route.params.error]
            }
        }
    },
	mounted(){
	console.log(process.env.VUE_APP_ROOT_API)
	}
}
</script>
<style scoped>

.form-container {
    width: 40% !important;
    height: 30% !important;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    max-width: 436px;
    max-height: 488px;
    box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
    -webkit-box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
    -moz-box-shadow: 1px 2px 53px 4px rgba(0,0,0,0.07);
    background-color: white;
    padding: 30px;
    min-width: 300px;
    min-width: 300px;
}

.page-layout {
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(https://eshyft.com/wp-content/uploads/2022/05/Website-Homepage.png);
}

.app-icon {
    margin-bottom: 20px !important;
}
</style>
