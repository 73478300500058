<template>
  <div>
    <a-row>
      <a-col :xs="12" style="padding: 10px 0 0 10px;">
        <h2>Email Addresses</h2>
      </a-col>
      <a-col :xs="12" style="text-align: right; padding: 10px 0;">
        <a-input-search
          v-model="keyword"
          placeholder="search by name or email"
          style="max-width: 500px;"
          allow-clear
          :loading="searching"
          @search="onSearch"
        />
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <a-table
          :data-source="subscriberList"
          :columns="visibleColumns"
          row-key="id"
          size="small"
          :pagination="{
            pageSize: pageSize,
            total: totalRows,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            showTotal: (total) => `Found ${total} Subscribers`
          }"
          :loading="loading"
          :custom-row="((record) => {
            return {
              on: {
                click: (e) => onRowClick(e, record)
              }
            }
          })"
          @change="handleChange"
        >

          <template slot="is_subscribe" slot-scope="text, record">
            <a-icon v-if="+record.is_subscribe === 1" type="check-circle" theme="outlined" :style="{ fontSize: '16px', color: '#52c41a' }" />
            <a-checkbox v-else-if="isAdmin" :default-checked=" +record.is_subscribe === 1" @change="handleSubscribe(record)" />
          </template>

          <template slot="is_unsubscribe" slot-scope="text, record">
            <a-icon v-if="+record.is_unsubscribe === 1" type="check-circle" theme="outlined" :style="{ fontSize: '16px', color: '#E36F66' }" />
            <a-checkbox v-else-if="isAdmin" :default-checked=" +record.is_unsubscribe === 1" @change="handleUnsubscribe(record)" />
          </template>

          <template slot="created_at" slot-scope="text, record">
            {{ record.created_at | moment("L") }}
          </template>

          <template slot="action" slot-scope="text, record">
            <a-space :size="3" v-if="isAdmin">
              <a-button type="link" size="small" icon="delete" @click="handleDelete(record)" />
              <a-button type="link" size="small" icon="eye" @click="handleOpenUpdate(record)" />
            </a-space>
          </template>
        </a-table>

        <SubscriberModal v-if="isAdmin" v-model="showSubscriberModal" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SubscriberModal from "./modal.vue";

export default {
  name: 'Subscribers',
  components: {
    SubscriberModal
  },
  data() {
      return {
        searching: false,
        keyword: null,
        columns: [
          {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '70px',
            scopedSlots: { customRender: 'action' },
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Subscribed',
            dataIndex: 'is_subscribe',
            key: 'is_subscribe',
            align: 'center',
            width: '100px',
            scopedSlots: { customRender: 'is_subscribe' },
          },
          {
            title: 'Unsubscribed',
            dataIndex: 'is_unsubscribe',
            key: 'is_unsubscribe',
            align: 'center',
            width: '100px',
            scopedSlots: { customRender: 'is_unsubscribe' },
          },
          {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            width: '100px',
            scopedSlots: { customRender: 'created_at' },
          },
        ],
        showSubscriberModal: false
      }
  },
  computed: {
    ...mapGetters({
      subscriberList: 'subscribers/subscriberList',
      subscriber: 'subscribers/getSubscriber',
      currentPage: 'subscribers/getCurrentPage',
      pageSize: 'subscribers/getPageSize',
      totalRows: 'subscribers/getTotalRows',
      totalPages: 'subscribers/getTotalPages',
      loading: 'subscribers/isLoading',
      auth: 'system_settings/GET_USER_DETAILS'
    }),
    isAdmin: function () {
      return this.auth?.user?.role?.trim() === 'ADMIN';
    },
    visibleColumns: function() {
      return this.isAdmin
        ? this.columns
        : this.columns.filter(i => i.key !== 'action');
    }
  },
  beforeMount() {
    this.$store.dispatch('subscribers/fetchSubscribers', { limit: this.pageSize, page: this.currentPage });
  },
  methods: {
    handleChange: function (pagination, filter, sorting) {
      if( pagination.current !== this.currentPage || pagination.pageSize !== this.pageSize ) {
        this.$store.dispatch('subscribers/fetchSubscribers', { limit: pagination.pageSize, page: pagination.current });
      }
    },
    handleSubscribe: function(record) {
      this.$store.dispatch('subscribers/subscribe', record);
    },
    handleUnsubscribe: function(record) {
      this.$store.dispatch('subscribers/unsubscribe', record);
    },
    onSearch: function () {
      this.searching = true;
      this.$store.dispatch('subscribers/fetchSubscribers', { limit: this.pageSize, page: 1, keyword: this.keyword });
      this.searching = false;
    },
    onRowClick: function(e, record) {
      if( !this.isAdmin) return;

      const el = (e.target || e.srcElement)?.tagName?.toLowerCase();
      if( el !== 'td' ) return;

      this.$store.dispatch('subscribers/setSubscriber', record);

      this.showSubscriberModal = true;
    },
    handleOpenUpdate: function (record) {
      this.$store.dispatch('subscribers/setSubscriber', record);
      this.showSubscriberModal = true;
    },
    handleDelete: function (record) {
      this.$confirm({
        title: 'Are you sure to delete this subscriber?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          this.deleting = true;

          try {
            this.$store.dispatch('subscribers/deleteSubscriber', { id: record.id });

            this.$success({
              title: 'Success',
              content: 'Subscriber has been successfully deleted!',
            });

            this.deleting = false;
          }catch(error) {
            this.deleting = false;

            this.$error({
              title: 'Failed',
              content: error.response.message
            });
          }
        }
      });
    }
  },
}
</script>
