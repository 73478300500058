import { render, staticRenderFns } from "./EmailForm.vue?vue&type=template&id=5b32b613&scoped=true&"
import script from "./EmailForm.vue?vue&type=script&lang=js&"
export * from "./EmailForm.vue?vue&type=script&lang=js&"
import style0 from "./EmailForm.vue?vue&type=style&index=0&id=5b32b613&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b32b613",
  null
  
)

export default component.exports