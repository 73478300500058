import axios from "axios";
import facilities from "./facilities";
import nurses from "./nurses";
import facilityStaffs from "./facility-staffs";
import eshyftStaffs from "./eshyft-staffs";

const { VUE_APP_ROOT_API } = process.env;

export default {
  namespaced: true,
  state: {
    is_logged_in: true,
    is_integrated: false,
    is_authorized: false,
  },
  getters: {
    getIsAuthorized: (state) => state.is_authorized,
    getIsIntegrated: (state) => state.is_integrated,
    getIsLoggedIn: (state) => state.is_logged_in,
  },
  actions: {
    async checkAuthorization({ commit }) {
      try {
        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/authorization`);

        commit('SET_IS_AUTHORIZED', !!request?.data?.authorized);
        commit('SET_IS_INTEGRATED', !!request?.data?.connected);
        commit('SET_IS_LOGGED_IN', !!request?.data?.isLoggedIn);

        return request?.data;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    },
    async checkIntegration({ commit }) {
      try {
        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/integrated`);
        commit('SET_IS_INTEGRATED', !!request?.data?.connected);

        return request?.data;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    },
  },
  mutations: {
    SET_IS_LOGGED_IN: (state, flag) => state.is_logged_in = flag,
    SET_IS_AUTHORIZED: (state, flag) => state.is_authorized = flag,
    SET_IS_INTEGRATED: (state, flag) => state.is_integrated = flag,
  },
  modules: {
    nurses,
    facilityStaffs,
    eshyftStaffs,
    facilities
  }
}
