<template>
    <textarea id="summer_note" :value="textContext"></textarea>
</template>

<script>
    import "../../../../node_modules/summernote/dist/summernote-lite.min.js"

    global.jQuery = require('jquery');
    var $ = global.jQuery;
    window.$ = $;

    export default {
        name: 'CustomSummerNote',
        props: {
          value: {
            type: String,
            default: '',
            require: false
          }
        },
        computed: {
          textContext: function () {
            return (this.value ?? '').trim();
          }
        },
        watch: {
          textContext: function (n) {
            if( n.trim() === '') {
              $('textarea#summer_note').summernote('code', '');
            }
          }
        },
        mounted() {
            let fontsArray = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto'];
            const vm = this;
            const options = {
              lang: 'en-US',
              height: 400,
              dialogsInBody: true,
              fontNames: fontsArray,
              fontNamesIgnoreCheck: fontsArray,
              fontSizes: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '20', '24', '30', '36', '48' , '64', '82', '150'],
              toolbar: [
                  ['style', ['style']],
                  ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                  ['fontname', ['fontname']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['table', ['table']],
                  ['color', ['color']],
                  ['insert', ['link', 'picture', 'video']],
                  ['view', ['fullscreen', 'codeview']],
                  ['height', ['height']],
                  ['fontsize', ['fontsize']]
              ]
            };

            options.callbacks = {
                onChange(contents) {
                  vm.$emit("input", contents);
                }
            };

            $('textarea#summer_note').summernote(options);
        },
    };
</script>

<style>
  @import "../../../../node_modules/summernote/dist/summernote-lite.min.css";
  @import '../../../assets/css/_editor.css';
</style>
