const parameters = new Proxy(new URLSearchParams(window.location.search), {
  get: (params, prop) => params.get(prop),
  has: (params, prop) => params.has(prop),
});

const urlParameter = {
  has: (key) => key in parameters,
  get: (key) => parameters[key],
};

module.exports = { urlParameter };
