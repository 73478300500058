<template>
    <div class="page-layout">
        <a-drawer
        title="Email Receiver"
        placement="right"
        :closable="true"
        :visible="is_show_receiver_form"
        :width="300"
        @close="on_form_close"
        >
            <a-form :form="form">
                <div class="d-flex flex-col">
                    <a-form-item label="Name">
                      <a-auto-complete
                        v-decorator="[
                          'name',
                          {
                            initialValue: prop_partial_data.name,
                            rules: [
                              { required: true, message: 'Name is required' }
                            ]
                          },
                        ]"
                        placeholder="Enter receiver name"
                        @search="handleNameSearch"
                        @select="handleSelect"
                      >
                        <template slot="dataSource">
                          <a-select-option v-for="name in nameSource" :key="name.id">
                            {{ name.text }}
                          </a-select-option>
                        </template>
                      </a-auto-complete>
                    </a-form-item>
                    <a-form-item label="E-mail">
                        <a-auto-complete
                          v-decorator="[
                            'email',
                              {
                                initialValue: prop_partial_data.email,
                                rules: [
                                  {
                                    type: 'email',
                                    message: 'The input is not valid e-mail',
                                  },
                                  {
                                    required: true,
                                    message: 'Email is required',
                                  },
                                ],
                             },
                          ]"
                          placeholder="Please input receiver email"
                          @search="handleEmailSearch"
                          @select="handleSelect"
                        >
                          <template slot="dataSource">
                            <a-select-option v-for="email in emailSource" :key="email.id">
                              {{ email.text }}
                            </a-select-option>
                          </template>
                        </a-auto-complete>
                    </a-form-item>
                    <a-button type="primary" class="mt-8" @click="save">
                        <a-icon type="check-circle" /> Save
                    </a-button>
                </div>
            </a-form>
        </a-drawer>
    </div>
</template>
<script>
import axios from "axios"

const { VUE_APP_ROOT_API } = process.env;

export default {
    name: 'ReceiverForm',
    props: [
        'is_show_receiver_form',
        'on_form_close',
        'partial_data',
        'on_save'
    ],
    data() {
        return {
          form: this.$form.createForm(this, { name: 'dynamic_rule' }),
          results: []
        };
    },
    computed: {
        prop_partial_data(){
          return this.partial_data
        },
        emailSource: function() {
          return this.results?.map(e => ({ id: e.id, value: e.email?.trim(), text: `${e.email} <${e.name}>` })) ?? [];
        },
        nameSource: function() {
          return this.results?.map(e => ({ id: e.id, value: e.name?.trim(), text: `${e.name} <${e.email}>` })) ?? [];
        }
    },
    methods: {
      save() {
        this.form.validateFields((err, values) => {
          if (!err) {
            values.key = this.prop_partial_data.key
            values.index = this.prop_partial_data.index
            this.on_save(values)
            this.form.resetFields()
          }
        });
      },
      searchList: async function ({ key, keyword }) {
        const xhr = await axios.get(`${VUE_APP_ROOT_API}search/subscriber?key=${key}&keyword=${keyword}`);
        this.results = xhr.data?.subscribers ?? [];
      },
      handleNameSearch: function(data) {
        this.searchList({ key: 'name', keyword: data.trim() });
      },
      handleEmailSearch: async function (data) {
        this.searchList({ key: 'email', keyword: data.trim() });
      },
      handleSelect: function (e) {
        const data = this.results?.filter(i => i.id === e)?.[0];

        this.form.setFieldsValue({
          name: data?.name?.trim(),
        });

        this.form.setFieldsValue({
          email: data?.email?.trim(),
        });
      }
    }
}
</script>
<style scoped>

</style>
