import axios from "axios";

const { VUE_APP_ROOT_API } = process.env;

export default {
    namespaced: true,
    state: {
        sidebar_visibility: false,
        form_modal_placement: 'right',
        user_details: {},
        user_photo: null
    },
    getters: {
        GET_SIDEBAR_VISIBILITY: state => state.sidebar_visibility,
        GET_FORM_MODAL_PLACEMENT: state => state.form_modal_placement,
        GET_USER_DETAILS: state => state.user_details,
        GET_USER_PHOTO: state => state.user_photo
    },
    actions: {
        GENERATE_DUMMY_USER({commit, state}){
            state.user_details = {
                user: {
                    displayName: 'Hello World',
                    emails: [
                        'tester@gmail.com'
                    ],
                    photos: [
                        'https://i.sstatic.net/34AD2.jpg'
                    ]
                }
            }
        },
        FETCH_USER_PHOTO: async ({ commit }) => {
          try {
            const request = await axios.get(`${VUE_APP_ROOT_API}users/photo`);
            commit('SET_USER_PHOTO', request?.data?.photo);

          } catch(error) {
            commit('SET_USER_PHOTO', null);
            console.log({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
          }
        }
    },
    mutations: {
        SET_SIDEBAR_VISIBILITY(state, status) {
            state.sidebar_visibility = status;
        },
        SET_USER_DETAILS(state, data) {
            state.user_details = data;
        },
        SET_USER_PHOTO: (state, data) => state.user_photo = data
    }
}
