<template>
    <a-drawer
      title="SalesForce Contacts"
      placement="right"
      :closable="true"
      :visible="isVisible"
      :width="420"
      :body-style="{ overflow: 'hidden', padding: 0 }"
      :drawer-style="{ overflow: 'hidden' }"
      destroy-on-close
      @close="handleOnClose"
    >
      <div>
        <a-tabs default-active-key="1" size="small">
          <a-tab-pane key="1" tab="Nurses">
            <SFNurseList v-model="selected_contacts" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Facility Staff">
            <SFFacilityStaffList v-model="selected_contacts" />
          </a-tab-pane>
          <a-tab-pane key="3" tab="ESHYFT Staff">
            <SFEshyftStaffList v-model="selected_contacts" />
          </a-tab-pane>
        </a-tabs>
      </div>

      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 8px" @click="handleOnClose">
          Cancel
        </a-button>
        <a-button
            icon="check-circle"
            type="primary"
            :loading="processing"
            :disabled="+selected === 0"
            @click="saveEmailReceivers"
          >
            Submit Receivers
          </a-button>
      </div>
    </a-drawer>
</template>

<script>
import SFNurseList from "./SFNurseList";
import SFFacilityStaffList from "./SFFacilityStaffList";
import SFEshyftStaffList from "./SFEshyftStaffList";

export default {
    name: 'SFContacts',
    components: {
      SFNurseList,
      SFFacilityStaffList,
      SFEshyftStaffList
    },
    props: {
      isVisible: {
        type: Boolean,
        require: false,
        default: false
      }
    },
    data() {
      return {
        processing: false,
        selected_contacts: []
      };
    },
    computed: {
      selected: function () {
        return this.selected_contacts.length;
      }
    },
    methods: {
      handleOnClose: function () {
        this.processing = false;
        this.selected_contacts.splice(0);
        this.$emit('onClose', true);
      },
      saveEmailReceivers: function () {
        this.processing = true;
        this.$emit('onSave', this.selected_contacts);
        this.handleOnClose();
      }
    }
}
</script>
