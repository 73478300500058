import axios from "axios";
import qs from "qs";

export default {
  namespaced: true,
  state: {
    subscribers: [],
    subscriber: {},
    page: 1,
    limit: 20,
    total: 1,
    pages: 1,
    loading: false
  },
  getters: {
    subscriberList: state => state.subscribers,
    getSubscriber: state => state.subscriber,
    getPageSize: state => +state.limit,
    getCurrentPage: state => +state.page,
    getTotalRows: state => +state.total,
    getTotalPages: state => +state.pages,
    isLoading: state => !!state.loading
  },
  actions: {
    async fetchSubscribers({ commit }, payload) {
      commit('SET_LOADING', true);

      const params = Object.keys(payload).filter(e => ![null, undefined, ''].includes(payload[e])).reduce((acc,curr)=> (acc[curr]=payload[curr],acc),{});
      const query = qs.stringify(params);

      const request = await axios.get(`${process.env.VUE_APP_ROOT_API}subscribers?${query}`);

      const data = request.data;

      commit('SET_SUBSCRIBERS', data.subscribers);
      commit('SET_CURRENT_PAGE', data.page);
      commit('SET_PAGE_SIZE', data.limit);
      commit('SET_TOTAL_ROWS', data.total);
      commit('SET_TOTAL_PAGES', data.pages);

      commit('SET_LOADING', false);
    },
    async fetchSubscriber({ commit }, payload) {
      commit('SET_LOADING', true);

      try {
        const request = await axios.get(`${process.env.VUE_APP_ROOT_API}subscribers/${payload.id}`);
        commit('SET_SUBSCRIBER', request.data);
        commit('SET_LOADING', false);

        return request;
      } catch(error) {
        commit('SET_LOADING', false);
        return Promise.reject({ message: error.message, code: error.code, status: error.response.status, statusText: error.response.statusText, response: error.response.data  });
      }
    },
    async subscribe({ commit }, payload) {
      commit('SET_LOADING', true);

      const request = await axios.patch(`${process.env.VUE_APP_ROOT_API}subscribers/${payload.id}`, { is_subscribe: 1, is_unsubscribe: 0 });
      commit('UPDATE_SUBSCRIBER', request?.data?.subscriber);

      commit('SET_LOADING', false);
    },
    async unsubscribe({ commit }, payload) {
      commit('SET_LOADING', true);

      const request = await axios.patch(`${process.env.VUE_APP_ROOT_API}subscribers/${payload.id}`, { is_subscribe: 0, is_unsubscribe: 1 });
      commit('UPDATE_SUBSCRIBER', request?.data?.subscriber);

      commit('SET_LOADING', false);
    },
    async updateSubscriber({ commit }, payload) {
      commit('SET_LOADING', true);

      try {
        const request = await axios.patch(`${process.env.VUE_APP_ROOT_API}subscribers/${payload.id}`, { name: payload.name, email: payload.email });
        commit('UPDATE_SUBSCRIBER', request?.data?.subscriber);
        commit('SET_LOADING', false);
        return request;
      } catch(error) {
        commit('SET_LOADING', false);
        return Promise.reject({ message: error.message, code: error.code, status: error.response.status, statusText: error.response.statusText, response: error.response.data  });
      }
    },
    async deleteSubscriber({ commit, state, dispatch }, payload) {
      commit('SET_LOADING', true);

      try{
        const request = await axios.delete(`${process.env.VUE_APP_ROOT_API}subscribers/${payload.id}`);
        commit('SET_LOADING', false);

        dispatch('fetchSubscribers', { limit: state.limit, page: state.page, keyword: state.keyword });

        return request;
      } catch(error) {
        commit('SET_LOADING', false);
        return Promise.reject({ message: error.message, code: error.code, status: error.response.status, statusText: error.response.statusText, response: error.response.data  });
      }
    },
    setSubscriber({ commit }, payload) {
      commit('SET_SUBSCRIBER', payload);
    }
  },
  mutations: {
    SET_SUBSCRIBERS(state, data) {
      state.subscribers = data;
    },
    SET_SUBSCRIBER(state, data) {
      state.subscriber = data;
    },
    SET_CURRENT_PAGE(state, page) {
      state.page = +page;
    },
    SET_PAGE_SIZE(state, limit) {
      state.limit = +limit;
    },
    SET_TOTAL_ROWS(state, total) {
      state.total = +total;
    },
    SET_TOTAL_PAGES(state, pages) {
      state.pages = +pages;
    },
    UPDATE_SUBSCRIBER(state, data) {
      const index = state.subscribers.findIndex(i => i.id === data.id );
      state.subscribers.splice(index, 1, data);
    },
    SET_LOADING(state, status) {
      state.loading = !!status;
    }
  }
}
