<template>
    <div v-if="is_show_on_sidebar" :class="'side-bar-item '+(current_route_path == path? 'active':'')" @click="visit">
        <span class="mr-2">
            <a-icon :type="icon" />
        </span>
        <span>
            {{ name }}
        </span>
    </div>
</template>
<script>
    import system_settings_types from '@/modules/store/system-settings/types.js'
    import { mapMutations } from "vuex";

    export default {
        name: 'SidebarItem',
        props: [
            'name',
            'path',
            'icon',
            'is_show_on_sidebar',
            'current_route_path'
        ],
        computed: {
        },
        methods: {
            ...mapMutations({
                setSidebarVisibility: system_settings_types.SET_SIDEBAR_VISIBILITY
            }),
            visit(){
              if( this.current_route_path !== this.path ) {
                this.$router.push(this.path)
              }
                this.setSidebarVisibility(false);
            }
        }
    }
</script>

<style scoped>
    .side-bar-item {
        width: 100% !important;
        cursor: pointer;
        padding: 1rem;
        color: gray !important;
    }

    .active {
        border-left: 4px solid #EE474E;
        color: #EE474E !important;
    }

    .side-bar-item:hover {
        background-color: #ee474e17
    }
</style>
