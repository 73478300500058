<template>
  <div class="sf-eshyft-staff-list">
    <div class="filter-section">
      <a-row type="flex" justify="space-between" align="middle" gutter="4">
        <a-col flex="1">
          <a-input-search
            v-model="search_keyword"
            placeholder="search keyword here"
            allow-clear
            :loading="is_loading_search"
            @search="handleSearch"
          >
            <a-select
              v-model="search_key"
              slot="addonBefore"
              :options="searchOptions"
              style="width: 130px;"
            />
          </a-input-search>
        </a-col>
      </a-row>
      <a-row style="padding-top: 10px;">
        <a-col>
          <a-radio-group :default-value="filter_by_owner" size="small" @change="onOwnerChange">
            <a-radio value="owned">
              My Contacts
            </a-radio>
            <a-radio value="all">
              All Staff
            </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-checkbox :indeterminate="indeterminate" :checked="isSelectedAll" @change="onSelectAllChange">
            {{ selectAllLabel }} ({{ totalSelected }})
          </a-checkbox>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          Loaded ({{ visible }})
        </a-col>
      </a-row>
    </div>

    <a-divider :style="{ margin: 0 }" />
    <div class="infinite-container">
      <a-spin :spinning="is_loading">
        <a-list :data-source="paginated">
          <div
            slot="loadMore"
            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
          >
            <div v-if="is_loading_more">
              <a-spin />
            </div>
            <div v-else>
              <a-button
                v-if="+paginated.length > 0 && !isLoadedAllItems"
                @click="loadMore"
              >
                Load more
              </a-button>
            </div>
          </div>

          <a-list-item
            slot="renderItem"
            slot-scope="item"
          >
            <SFContactItem :value="item" v-model="selected" />
          </a-list-item>
        </a-list>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SFContactItem from './SFContactItem';

export default {
  components: {
    SFContactItem
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: {
      type: Array,
      require: false,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      paginated: [],
      pagination: null,
      is_loading: true,
      search_key: 'Name',
      search_keyword: null,
      is_loading_search: false,
      is_loading_more: false,
      searchOptions: [
        { value: 'Name', label: 'Name' },
        { value: 'Email', label: 'Email' }
      ],
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      sf_facilities: 'salesforce/facilities/getFacilities',
      sf_contacts: 'salesforce/eshyftStaffs/getContacts',
      filter_by_owner: 'salesforce/eshyftStaffs/getFilterByOwner',
      total_size: 'salesforce/eshyftStaffs/getTotalSize'
    }),
    indeterminate: function () {
      return this.selected.length > 0 && (this?.paginated?.length !== this.selected.length);
    },
    isSelectedAll: function () {
      return this.selected.length > 0 && (this?.paginated?.length === this.selected.length);
    },
    selectAllLabel: function () {
      return this.isSelectedAll ? 'Unselect All' : 'Select All';
    },
    totalSelected: function () {
      return +this.selected.length;
    },
    visible: function () {
      return `${this.paginated.length} / ${this.total_size}`;
    },
    isLoadedAllItems: function() {
      return +this.paginated.length >= +this.total_size;
    }
  },
  watch: {
    selected: function (n ,o) {
      if( +n?.length !== +o?.length ) {
        this.$emit("change", [ ...n ]);
      }
    },
    modelValue: function (n, o) {
      if( +n?.length !== +o?.length ) {
        this.selected = [...n];
      }
    },
    filter_by_owner: async function (n, o) {
      if(n != o) {
        this.paginated.splice(0);

        this.is_loading = true;

        const { filters, search_key: field = null, search_keyword: keyword = null } = this;
        await this.$store.dispatch('salesforce/eshyftStaffs/fetchContacts', { filters, field, keyword, owner: n });

        this.pagination = this.paginate();
        const initial = this.pagination.next();
        if( initial?.value ) {
          this.paginated = [ ...this.paginated, ...initial.value ];
        }

        this.is_loading = false;
      }
    }
  },
  async beforeMount() {
    this.paginated.splice(0);

    if(+this.sf_contacts?.length === 0) {
      const { filters, search_key: field = null, search_keyword: keyword = null, filter_by_owner: owner = null } = this;
      await this.$store.dispatch('salesforce/eshyftStaffs/fetchContacts', { filters, field, keyword, owner });
    }

    if(+this.sf_facilities?.length === 0) {
      await this.$store.dispatch('salesforce/facilities/fetchFacilities');
    }

    this.pagination = this.paginate();
    const initial = this.pagination.next();
    if( initial?.value ) {
      this.paginated = [ ...this.paginated, ...initial.value ];
    }

    this.is_loading = false;
  },
  methods: {
    onSelectAllChange: function (e) {
      this.selected.splice(0);

      if ( e.target.checked ) {
        this.selected = [ ...this.paginated ];
      } else {
        this.$emit("change", []);
      }
    },
    handleSearch: async function() {
      this.is_loading_search = true;

      const { filters, search_key: field = null, search_keyword: keyword = null, filter_by_owner: owner = null } = this;
      await this.$store.dispatch('salesforce/eshyftStaffs/fetchContacts', { filters, field, keyword, owner });

      this.paginated.splice(0);
      this.pagination = this.paginate();
      const next = this.pagination.next();
      if( next?.value ) {
        this.paginated = [ ...this.paginated, ...next.value ];
      }

      this.is_loading_search = false;
    },
    loadMore: async function () {
      this.is_loading_more = true;

      if( +this.paginated.length >= +this.sf_contacts.length ) {
        const { filters, search_key: field = null, search_keyword: keyword = null, filter_by_owner: owner = null } = this;
        const records = await this.$store.dispatch('salesforce/eshyftStaffs/fetchMoreContacts', { filters, field, keyword, owner });

        this.pagination = this.paginate({ records });
      }

      const next = this.pagination.next();
      if( next?.value ) {
        this.paginated = [ ...this.paginated, ...next.value ];
      }

      this.is_loading_more = false;
    },
    onOwnerChange: function (e) {
      this.$store.dispatch('salesforce/eshyftStaffs/setFilterByOwner', e.target.value);
    },
    paginate: function* (array) {
      const size = 300;
      let clone = [];

      if( array?.records ) {
        const { records } = array;
        clone = records.slice(0);
      } else {
        clone = this.sf_contacts.slice(0);
      }

      while (clone.length > 0) {
        yield clone.splice(0, size);
      }
    }
  }
}
</script>

<style scoped>
.sf-eshyft-staff-list >>> .ant-list-item {
  padding: 5px 0;
}
.sf-eshyft-staff-list >>> .infinite-container {
  height: 65dvh;
  padding-left: 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sf-eshyft-staff-list >>> .filter-section {
  padding-bottom: 5px;
  padding-left: 24px;
  padding-right: 24px;
}
.sf-eshyft-staff-list >>> .filter-section .ant-row {
  margin-bottom: 10px;
}
</style>
