import axios from "axios";

export default {
    namespaced: true,
    state: {
        form: {
            receivers: [],
            email_subject: '',
            email_content: '',
            max_key: 0
        }
    },
    getters: {
        GET_FORM_FIELD_VALUE: state => state.form,
        GET_EMAIL_RECEIVERS: state => state.form.receivers
    },
    actions: {
        CLEAR_FORM_FIELDS({commit, state}){
            state.form = {
                receivers: [],
                email_subject: null,
                email_content: null,
                max_key: 0
            }
        },
        DELETE_RECEIVER({commit, state}, key){
            var index = state.form.receivers.findIndex(function(item, i){
                return item.key === key
            });
            if (index > -1) { // only splice array when item is found
                state.form.receivers.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
        SORT_RECEIVERS({commit, state}, data){
            if(data.order == 'ascend'){
                state.form.receivers.sort((a, b) => a[data.column].localeCompare(b[data.column]))
            }else{
                state.form.receivers.sort((a, b) => b[data.column].localeCompare(a[data.column]))
            }
        },
        ADD_NEW_RECEIVER({commit, state}, data){
            delete data.index
            state.form.max_key++
            data.key = state.form.max_key
            state.form.receivers.push(data)
        }
    },
    mutations: {
        SET_FORM_FIELD_VALUE(state, data) {
            state.form[data.key] = data.value
        },
        SET_RECEIVER_DATA(state, data){
            state.form.receivers[data.index] = data.value
        }
    }
}
