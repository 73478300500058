<template>
  <a-drawer
      title="Advance Filter"
      :width="420"
      :body-style="{ overflow: 'hidden' }"
      :drawer-style="{ overflow: 'hidden' }"
      :closable="false"
      :visible="isVisible"
    >
    <div class="advance-filter-section">
      <a-row style="padding-bottom: 10px;">
        <a-col>
          <a-form-item label="Position">
            <a-checkbox-group
              :default-value="filterByPosition"
              :options="f_contact_position_options"
              @change="handlePositionChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="padding-bottom: 10px;">
        <a-col>
          <a-form-item label="License">
            <a-checkbox-group
              :default-value="filterByLicense"
              :options="f_contact_license_options"
              @change="handleLicenseChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="padding-bottom: 10px;">
        <a-col>
          <a-form-item label="Contact Stage">
            <a-checkbox-group
              :default-value="filterByContactStage"
              :options="f_contact_stage_options"
              @change="handleContactStageChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider>
        <span style="font-size: 14px;">NEARBY CONTACTS</span>
      </a-divider>
      <a-row style="padding-bottom: 10px;">
        <a-col>
          <div class="ant-form-item-label">
            <label title="Facility">Facility</label>
          </div>
          <FacilitySelect :value="filterByFacility" @selected="handleFacilitySelect" />
        </a-col>
      </a-row>
      <a-row style="padding-bottom: 10px;">
        <a-col>
          <a-form-item label="Miles Radius">
            <a-radio-group :default-value="filterByNearbyContactRadius" @change="handleNearbyContactRadiusChange">
              <template v-for="(f_radius, index) in f_contact_radius_options">
                <a-radio :key="index" :value="f_radius">
                  {{  f_radius }} Miles
                </a-radio>
              </template>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="(!!nearby_contact_radius && !!facility) && (!location?.latitude || !location?.longitude)">
        <a-col>
          <a-alert
            message="Cannot filter nearby contacts"
            description="Geo Location is missing on the selected facility."
            type="error"
            show-icon
          />
        </a-col>
      </a-row>
    </div>

    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 8px" @click="handleCancelFilter">
          Cancel
        </a-button>
        <a-button
            icon="check-circle"
            type="primary"
            @click="handleApplyFilters"
          >
            Apply Filters
          </a-button>
      </div>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import FacilitySelect from '../../common/FacilitySelect';

export default {
  components: { FacilitySelect },
  model: {
    prop: 'value',
    event: 'submit'
  },
  props: {
    value: {
      type: Object,
      require: false,
      default: () => {}
    },
    visible: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      facility: null,
      position: null,
      license: null,
      contact_stage: null,
      nearby_contact_radius: null,
      location: {},
      is_loading_search_facility: false,
      f_contact_license_options: ['CNA', 'LPN', 'RN'],
      f_contact_stage_options: ['New Lead Status', 'Applicant', 'Approved', 'ESHYFT User'],
      f_contact_position_options: ['CNA', 'LPN', 'RN', 'Supervisor', 'Admin', 'facility-manager', 'Nurse'],
      f_contact_radius_options: [10, 20, 30],
      selectedFacility: null
    }
  },
  computed: {
    ...mapGetters({
      sf_facilities: 'salesforce/facilities/getFacilities'
    }),
    filters: function () {
      const { facility, position, license, contact_stage, nearby_contact_radius, location, selectedFacility } = this;
      return { facility, position, license, contact_stage, nearby_contact_radius, location, selectedFacility };
    },
    filterByFacility: function () {
      return this.value?.selectedFacility || null;
    },
    filterByPosition: function () {
      return this.value?.position || null;
    },
    filterByLicense: function () {
      return this.value?.license || null;
    },
    filterByContactStage: function () {
      return this.value?.contact_stage || null;
    },
    filterByNearbyContactRadius: function () {
      return this.value?.nearby_contact_radius || null;
    },
    isVisible: function () {
      return !!this.visible;
    }
  },
  watch: {
    value: {
      handler: function(n, o) {
        this.facility = n?.facility || null;
        this.position = n?.position || null;
        this.license = n?.license || null;
        this.contact_stage = n?.contact_stage || null;
        this.nearby_contact_radius = n?.nearby_contact_radius || null;
        this.location = n?.location || {};
        this.selectedFacility = n?.selectedFacility || {};
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleApplyFilters: function () {
      this.$store.dispatch('salesforce/nurses/setFilters', this.filters);

      const filters = { ...this.filters };
      delete filters.selectedFacility;
      this.$emit('submit', filters);
    },
    handleNearbyContactRadiusChange: function (e) {
      this.nearby_contact_radius = e.target.value;
    },
    handlePositionChange: function (selected) {
      this.position = selected;
    },
    handleLicenseChange: function (selected) {
      this.license = selected;
    },
    handleContactStageChange: function (selected) {
      this.contact_stage = selected;
    },
    handleFacilitySelect: function (selected) {
      const { id, location } = selected || {};

      this.selectedFacility = selected;

      this.facility = id;
      this.location = location;
    },
    handleCancelFilter: function () {
      this.$emit("close", true);
    }
  }
}
</script>

<style scoped>
.advance-filter-section {
  height: 90dvh;
}
.ant-form-item >>> .ant-form-item-label {
  padding: 0;
  line-height: 1.5;
}

.ant-form-item >>> .ant-form-item-control {
  line-height: 1.5;
}

.ant-alert-with-description {
  padding: 8px 15px 8px 37px;
}

.ant-alert-with-description >>> .ant-alert-icon {
  top: 6px;
  left: 6px;
}
</style>
