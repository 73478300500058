import axios from "axios";

const { VUE_APP_ROOT_API } = process.env;

export default {
  namespaced: true,
  state: {
    facilities: [],
    sf_next_facility_record_url: null,
    total_size: 0
  },
  getters: {
    getFacilities: (state) => state.facilities,
    getFilterByOwner: (state) => state.filter_by_owner,
    getTotalSize: (state) => +state.total_size
  },
  actions: {
    async fetchFacilities({ commit, state }, payload) {
      try {
        let data = {};
        if( payload ) {
          data = { params: { ...payload } };
        }

        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/facilities`, data);
        const results = request?.data?.records
          ?.map( i => ({
            id: i?.Id?.trim(),
            name: i?.Name?.trim(),
            email: i?.Facility_Email__c?.trim(),
            facility_stage: i?.Contact_Stage__c?.trim(),
            address: i?.Full_Address__c?.trim(),
            location: i?.Geometry__c,
            type: 'facility'
          })
        ) || [];

        commit('SET_TOTAL_SIZE', +(request?.data?.totalSize || 0));
        commit('SET_SF_FACILITIES', results);
        commit('SET_SF_NEXT_FACILITY_RECORD_URL', request?.data?.nextRecordsUrl || null);

        return results;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    },

    async fetchMoreFacilities({ commit, state }, payload) {
      try {

        let data = { params: { page: state.sf_next_facility_record_url } };
        if( payload ) {
          data = { params: { ...payload, page: state.sf_next_facility_record_url } };
        }

        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/facilities`, data);
        const results = request?.data?.records
          ?.map( i => ({
            id: i?.Id?.trim(),
            name: i?.Name?.trim(),
            email: i?.Facility_Email__c?.trim(),
            facility_stage: i?.Contact_Stage__c?.trim(),
            address: i?.Full_Address__c?.trim(),
            location: i?.Geometry__c,
            type: 'facility'
          })
        ) || [];

        commit('SET_SF_FACILITIES', [...state.facilities, ...results]);
        commit('SET_SF_NEXT_FACILITY_RECORD_URL', request?.data?.nextRecordsUrl || null);

        return results;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    }
  },
  mutations: {
    SET_SF_FACILITIES: (state, data) => state.facilities = data,
    SET_SF_NEXT_FACILITY_RECORD_URL: (state, url) => state.sf_next_facility_record_url = url,
    SET_TOTAL_SIZE: (state, total) => state.total_size = +total
  }
}
