import axios from "axios";

const { VUE_APP_ROOT_API } = process.env;

export default {
  namespaced: true,
  state: {
    contacts: [],
    sf_next_contact_record_url: null,
    filter_by_owner: 'all',
    total_size: 0
  },
  getters: {
    getContacts: (state) => state.contacts,
    getFilterByOwner: (state) => state.filter_by_owner,
    getTotalSize: (state) => +state.total_size
  },
  actions: {
    async fetchContacts({ commit, state }, payload) {
      try {
        let data = { params: { type: 'eshyft_staff' } };
        if( payload ) {
          data = { params: { ...payload, type: 'eshyft_staff' } };
        }

        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/contacts`, data);
        const results = request?.data?.records
          ?.filter( i => i?.Name?.trim()?.length > 0 && i?.Email?.trim()?.length > 0)
          ?.map( i => ({
            name: i?.Name?.trim(),
            email: i?.Email?.trim(),
            position: i?.Position__c?.trim(),
            license: i?.License__c?.trim(),
            contact_stage: i?.Contact_Stage__c?.trim(),
            account: i?.Account?.Name?.trim(),
            type: 'contact'
          })
        ) || [];

        commit('SET_TOTAL_SIZE', +(request?.data?.totalSize || 0));
        commit('SET_SF_CONTACTS', results);
        commit('SET_SF_NEXT_CONTACT_RECORD_URL', request?.data?.nextRecordsUrl || null);

        return results;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    },

    async fetchMoreContacts({ commit, state }, payload) {
      try {

        let data = { params: { page: state.sf_next_contact_record_url } };
        if( payload ) {
          data = { params: { ...payload, page: state.sf_next_contact_record_url } };
        }

        const request = await axios.get(`${VUE_APP_ROOT_API}integration/salesforce/contacts`, data);
        const results = request?.data?.records
          ?.filter( i => i?.Name?.trim()?.length > 0 && i?.Email?.trim()?.length > 0)
          ?.map( i => ({
            name: i?.Name?.trim(),
            email: i?.Email?.trim(),
            position: i?.Position__c?.trim(),
            license: i?.License__c?.trim(),
            contact_stage: i?.Contact_Stage__c?.trim(),
            account: i?.Account?.Name?.trim(),
            type: 'contact'
          })
        ) || [];

        commit('SET_SF_CONTACTS', [...state.contacts, ...results]);
        commit('SET_SF_NEXT_CONTACT_RECORD_URL', request?.data?.nextRecordsUrl || null);

        return results;
      } catch(error) {
        return Promise.reject({ message: error?.message, code: error?.code, status: error?.response?.status, statusText: error?.response?.statusText, response: error?.response?.data  });
      }
    },

    setFilterByOwner: ({ commit }, payload ) => commit('SET_SF_FILTER_BY_OWNER', payload)
  },
  mutations: {
    SET_SF_CONTACTS: (state, data) => state.contacts = data,
    SET_SF_NEXT_CONTACT_RECORD_URL: (state, url) => state.sf_next_contact_record_url = url,
    SET_SF_FILTER_BY_OWNER: (state, data) => state.filter_by_owner = data,
    SET_TOTAL_SIZE: (state, total) => state.total_size = +total
  }
}
